@import '../node_modules/material-design-icons/iconfont/material-icons.css';
@import "assets/scss/mixins";

body {
  width: 100%;
  overflow-x: hidden;
}

a {
  color: #0663c6 !important;
  text-decoration: none !important;
}

.no_bottom_margin {
  margin-bottom: 0;
}

.button_radius {
  border-radius: 0 !important;
}

.custom_heading_color {
  color: nw-color('blue', funBlue);
}

.cursor_pointer {
  cursor: pointer;
}

.btn_back_ground_blue {
  background-color: nw-color('blue', funBlue);
}

.textColorBlue {
  color: nw-color('blue', funBlue) !important;
  cursor: pointer;
}

.material-input-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.material-input-full-width {
  width: 100%;
}


.mat-mdc-form-field .mat-icon {
  color: nw-color('blue', funBlue);
  margin-right: 10px;
}

.mat-mdc-form-field .mat-label {
  font-size: 18px;
}

html, body {
  height: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
body, .mat-mdc-tab-group, .mat-tab-label, .mat-mdc-tab-link {
  margin: 0;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif !important;
}

.icon-vert-align {
  display: inline-flex;
  margin: auto;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: #000;
  opacity: 0.75 !important;
}

.custom_tabs {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  mat-tab-header {
    margin-bottom: 50px;
  }

  .mat-mdc-tab-body-content {
    overflow-x: hidden;
  }

  .mdc-tab--active {
    flex-grow: 1;
    opacity: 1;

    .mdc-tab__content {
      font-weight: 700;
    }
  }

  .mdc-tab-indicator__content--underline {
    height: 4px;
  }
}

.home_slider {
  .carousel-inner {
    padding-left: 0;
    padding-right: 0;
  }

  .carousel-nav {
    height: 33px;
    width: 80px;
    display: inline-block;
    position: absolute;
    z-index: 99;
    top: 112px;
    &:focus {
    }
    &.prev {
      left: 0;
    }
    &.next {
      right: 0;
      left: 30%;
    }
  }
 
  .carousel-control-next, .carousel-control-prev {
    width: 40px;
    height: 30px;
    top: 100px;
    margin-top: -15px;
    cursor: pointer;

    span  {
      display: none;
    }
    &:focus {
      outline: 2px solid #a9cdfe !important; 
    }
     
  }
}

button:focus {
  border: 2px solid #a9cdfe !important; ;
}

.pet-slider {
  .carousel-control-next, .carousel-control-prev {
    width: 40px;
    height: 30px;
    top: 100px;
    cursor: pointer;

    span {
      display: none;
    }
  }
}

carousel.slide-animated {
  slide.item {
    animation-duration: 1s;
    animation-fill-mode: both;

    &.active {
      width: 100%;
      animation-name: carouselSlideIn;
      display: block;
    }

    &:not(.active) {
      display: table-cell;
      animation-name: carouselSlideOut;
      display: none;
    }
  }
}

@keyframes carouselSlideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes carouselSlideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
mat-progress-bar.mat-mdc-progress-bar {
  // height: 8px;
  --mdc-linear-progress-active-indicator-height: 8px;
  --mdc-linear-progress-active-indicator-color: #0067C5;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgb(66, 134, 244);
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent {
  color: rgb(66, 134, 244);
  --mdc-radio-selected-focus-icon-color: rgb(66, 134, 244);
    --mdc-radio-selected-hover-icon-color: rgb(66, 134, 244);
    --mdc-radio-selected-icon-color: rgb(66, 134, 244);
    --mdc-radio-selected-pressed-icon-color: rgb(66, 134, 244);
    --mat-radio-ripple-color: rgb(66, 134, 244);
    --mat-radio-checked-ripple-color: rgb(66, 134, 244);
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(66, 134, 244, .26);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-input-error .mat-form-field-subscript-wrapper {
  position: relative;
}

@media screen and (min-width: 768px) {
  .custom_tabs {
    .mat-mdc-tab-label-container {
      flex-grow: 0;
    }

    .mat-mdc-tab-header {
      padding-left: 155px;
    }
  }
  .home_slider {
    .carousel-inner slide {
      padding-left: 50px;
      padding-right: 50px;
    }

    .carousel-control-next, .carousel-control-prev {
      width: 50px;
      height: 30px;
      top: 50%;
      margin-top: -15px;
    }
  }
}

.mat-drawer-container {
  background-color: white !important;
}

.custom_button {
  background-color: darkblue !important;
  color: white !important;
}

.file-new-claim-link {
  color: white !important;
}

.custom-mat-button {
  width: 180px !important;
  height: 50px !important;
  font-size: large !important;
}

.custom-header-image {
  background-image: url('./assets/images/svg-icons/nw_portal_icon_150px_profile_dog.svg');
  background-size: cover;
}

.custom-cancel-button {
  height: 50px !important;
  width: 200px !important;
  font-size: large !important;
}

.custom-account-button {
  height: 50px !important;
  width: 200px !important;
  font-size: large !important;
}
.custom-account-button.mat-mdc-raised-button.mat-mdc-button-base{
  height: 50px;
}
@media (max-width: 768px) {
  .custom_tabs {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label {
      width: 50%;
    }

    .mat-ink-bar {
      width: 50%;
    }
  }

}

@media screen and (min-width: 992px) {
  .custom_tabs {
    z-index: 99;
    position: relative;
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    mat-tab-header {
      margin-right: 320px;
      z-index: 999;
      margin-bottom: 10px;
    }

  }
}

.container-title {
  text-align: center;
  h1 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  .container-title {
    text-align: left;
  }

}

@media (max-width: 768px) {
  header {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-list-item-content {
      position: absolute !important;
      right: 0px;
      top: 15px;
    }
  }
  .mat-mdc-list-base .mat-mdc-list-item.mobile-hide {
    display: none;
  }

}


.payment-type {
  .payment_item {
    display: flex;
    flex-direction: row;
    padding: 15px;
    border: 1px solid #ccc;
    margin-bottom: 15px;

    .mat-mdc-radio-button {
      display: flex;
    }

    input {
      margin-top: 5px;
    }

    cursor: pointer;

    .payment_info {
      padding-left: 15px;

      p {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .mobile-button {
    width: 100% !important;
    padding-left: 15px;
    padding-right: 15px;
    font-size: large;
  }
}

.recent-claim-left {
  display: flex;

  img {
    width: 30px;
    height: 30px;
  }

  h3 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: nw-color('blue', funBlue);
    margin-bottom: 0;
    font-weight: 700;
    padding-left: 5px;
    font-size: 18px;
  }
}

.custom_cursor {
  cursor: pointer;
}

.termsPopup h4 {
  color: nw-color('blue', funBlue);
}

.singleselect .multiselect-item-checkbox {
padding: 0px !important;
}
.singleselect input[type=checkbox]+div:before {
display: none;
}

.singleselect input[type=checkbox]+div:after {
display: none;
}

.singleselect input[type=checkbox]+div {
padding: 6px 10px !important;
}

.singleselect .multiselect-dropdown .dropdown-btn .selected-item a {
    text-decoration: none;
    display: none;
}

.selectpet_editpayment .multiselect-dropdown .dropdown-btn .selected-item a {
    text-decoration: none;
    display: none;
}

.singleselect input[type=checkbox]:checked+div {
background-color: #337ab7 !important;
color: #fff !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
    border: 1px solid #fff !important;
    margin-right: 4px !important;
    background: #fff !important;
    padding: 0 5px !important;
    color: #000 !important;
    border-radius: 2px !important;
    float: left !important;
}

.multiselect-dropdown .dropdown-btn .selected-item a {
    color: black !important;
}

.multiselect-dropdown .dropdown-btn .selected-item:hover {
     box-shadow: none !important;
}

.singleselect .multiselect-dropdown .dropdown-btn {
    // border: 1px solid #e0e0e0 !important;
    border: none !important;
}

.multiselect-dropdown .dropdown-btn {
    // border: 1px solid #e0e0e0 !important;
    border: none !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-down {
    display: inline-block;
    top: -34px;
    width: -17px;
    height: 1px;
    border-top: 7px solid #adadad !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
    display: none !important;//inline-block;
    width: 0;
    height: 8px;
    border-top: 7px solid #adadad !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
}

.accountype-editpy .multiselect-dropdown {
    position: relative;
    width: 90% !important;
    font-size: inherit;
    font-family: inherit;
    margin-left: 25px !important;
}

.fieldSet-multiselect {
    border: 1px solid #e0e0e0 !important;
    border-radius: 5px !important;
    margin-bottom: 20px !important;
}

.legend-multiselect{
    font-size: 12px;
    padding-left: 5px;
    margin-left: 21px;
    line-height: 1px;
    width: fit-content;
    display: block !important;
}

.mat-icon-multiselect{
  position: absolute;
    color: #1c57a5;
    padding: 6px;
}

.mat-mdc-form-field-error{
  color: #E60000 !important;
}

.mat-req{
  color: #E60000 !important;
}

.mat-error-input{
  border-color:  #E60000 !important;
  color: #E60000 !important;
}

.truste_cursor_pointer {
    text-decoration: none;
    color: #fff!important;
    display: inline-block;
    font-size: .8125rem!important
}

.mdc-button{
  letter-spacing: var(--mdc-typography-button-letter-spacing,none);
}

:host{
  display: block;
}

.mat-mdc-card{
  padding: 16px !important;
}

.mat-mdc-card-content:first-child{
  font-size: 14px !important;
}